import React from "react"
import styled from "styled-components"
import Layout from "../components/layout/layout"
import media from "styled-media-query"
import SEO from "../components/seo"

const Contact = props => {
  return (
    <>
      <SEO
        title="Get in Contact"
        description="Im always excited to hear what people have to say!"
      />
      <Layout>
        <Container>
          <h1>Get in Contact</h1>

          <h3>
            I always love to hear ways to improve Show StopWatch or bugs that i
            have missed! So please get incontact.
          </h3>
          <Form method="post" action="https://formspree.io/f/xaylpdqa">
            <InputField
              label="Name"
              type="text"
              placeholder="Your name"
              required
            />
            <InputField
              label="Email"
              type="text"
              placeholder="email"
              required
            />

            <Label fullWidth>
              Message
              <textarea
                name="message"
                id="message"
                required
                rows="5"
                placeholder="Let us know how we can help"
              />
            </Label>
            <Button type="submit">Send Request.</Button>
          </Form>
        </Container>
      </Layout>
    </>
  )
}

export default Contact

const InputField = ({ label, type, placeholder, required, fullWidth }) => (
  <Label fullWidth={fullWidth}>
    <div>
      {label}
      {!required && <Optional> - optional</Optional>}
    </div>
    <Input
      type={type}
      name={label}
      placeholder={placeholder}
      required={required}
    />
  </Label>
)
const Form = styled.form`
  flex: 1;
  background-color: #505050;
  padding: 1em;
  border-radius: 20px;
  width: 80%;
  box-shadow: 1px 3px 5px 4px #202020;
  ${media.greaterThan("large")`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  `};
  textarea {
    resize: none;
    margin-top: 6px;
    font-weight: normal;
    width: 100%;
    display: inline-block;
    line-height: 1.3;
    box-sizing: border-box;
    color: white;
    background-color: rgb(43, 43, 43);
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    outline: none;
    padding: 14px 20px;
    border-radius: 3px;
  }
`
const Button = styled.button`
  font-weight: bold;
  font-size: 18px;
  line-height: 48px;
  height: 50px;
  background-color: white;
  color: rgb(20, 20, 20);
  text-align: center;
  cursor: pointer;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: none;
  padding: 0px 24px;
  border-radius: 3px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  :hover {
    background-color: rgba(255, 255, 255, 0.7);
    color: rgb(20, 20, 20);
  }
  width: 100%;
  ${media.greaterThan("large")`
    width: unset;
  `};
`

const Input = styled.input`
  margin-top: 6px;
  font-weight: normal;
  /* width: 60%; */
  display: inline-block;
  line-height: 1;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  background-color: rgb(43, 43, 43);
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: none;
  padding: 14px 20px;
  border-radius: 3px;
  /* border: 1px solid red; */
`

const Optional = styled.span`
  font-size: 16px;
  font-style: italic;
`

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  padding-bottom: 16px;
  color: white;

  .text {
  }

  ${media.greaterThan("large")`
    margin-bottom: 0;
    flex-basis: ${p => (p.fullWidth ? "100" : "46")}%;
    margin-right: 4%;
  `};
`

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  /* /* align-items: center; */
  flex-direction: column;
  align-items: center;

  margin: auto;
  a {
    color: white;
  }
  p {
    max-width: 500px;
  }

  ${media.greaterThan("large")`
    max-width: 750px;
    height: 700px;
    min-height: 700px;
  `};
  min-height: 85vh;

  h1 {
    color: white;
    text-align: center;
    padding: 1em;
  }

  h3 {
    color: white;
    font-weight: 250;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
  }
`
